// Functionality: This file exports the VPDataSidebar component which is used in the Vehicle Performance Page Data component.
import Accordion from "../../../components/Accordion";
import Button from "../../../components/Button";
import RangeSlider from "../../../components/RangeSlider";
import SearchBar from "../../../components/SearchBar";
import filter from "../../../assets/icons/filter.png";

export default function VPDataSidebar({
  page,
  filtersIsLoading,
  filterVehicle,
  setFilterVehicle,
  clearButtonFilter,
  setClearButtonFilter,
  setFilterString,
  setVehicleProfileDataPage,
  vpDataSelectedColumns,
  setVpDataSelectedColumns,
  addEditSidebarVPColumnsOptions,
  vehiclePerformanceFilterSidebar,
  setVehiclePerformanceFilterSidebar,
  generateSidebarFilterString,
  setPartialSearchFilterString = () => {},
}) {
  // Const: Filter Columns to Slice
  const filterColumnsSlice = page === "data" ? 15 : 8;

  // Const: Filter Header
  const filterHeader = (
    <div className="vp-sidebar-header-group">
      <p>Vehicle Performance Data</p>
      <img src={filter} alt={"filter"} />
    </div>
  );

  // Function: Handle Dropdown Change
  const handleDropdownChange = (option, type = "vehicle") => {
    if (type === "vehicle") {
      setFilterVehicle((prevSelectedOptions) => {
        const isSelected = prevSelectedOptions?.data?.some(
          (item) => item.value === option.value
        );
        if (isSelected) {
          return {
            ...prevSelectedOptions,
            data: prevSelectedOptions?.data?.filter(
              (item) => item.value !== option.value
            ),
          };
        } else {
          return {
            ...prevSelectedOptions,
            data: [...prevSelectedOptions?.data, option],
          };
        }
      });
    } else {
      setVpDataSelectedColumns({
        ...vpDataSelectedColumns,
        edit: vpDataSelectedColumns?.edit?.some(
          (item) => item.value === option.value
        )
          ? vpDataSelectedColumns?.edit?.filter(
              (item) => item.value !== option.value
            )
          : [...vpDataSelectedColumns?.edit, option],
      });
    }
  };

  // Const: Vehicle Filters
  const vehicleFilters = !filtersIsLoading && (
    <Accordion
      type="vehicleFilters"
      titleName="Search Specific Vehicles"
      children={
        <div className="vp-sidebar-nested-group">
          <SearchBar
            options={filterVehicle?.options}
            isMultiSelect={true}
            selectedOptions={filterVehicle?.data}
            onChange={(e) => handleDropdownChange(e)}
          />
        </div>
      }
      titleClassName="vp-sidebar-accordion-group"
    />
  );
  const handleFilterValueChange = (column, newValues) => {
    setVehiclePerformanceFilterSidebar({
      ...vehiclePerformanceFilterSidebar,
      filters: {
        ...vehiclePerformanceFilterSidebar?.filters,
        [column]: {
          ...vehiclePerformanceFilterSidebar?.filters?.[column],
          data: newValues,
        },
      },
    });
  };
  const handleFilterInputChange = (column, event, value) => {
    const newValue = event.target.value.trim();
    if (value === "min") {
      if (newValue === "") {
        setVehiclePerformanceFilterSidebar({
          ...vehiclePerformanceFilterSidebar,
          filters: {
            ...vehiclePerformanceFilterSidebar?.filters,
            [column]: {
              ...vehiclePerformanceFilterSidebar?.filters?.[column],
              data: [
                "",
                vehiclePerformanceFilterSidebar?.filters?.[column]?.data[1],
              ],
            },
          },
        });
      } else {
        if (
          newValue > vehiclePerformanceFilterSidebar?.filters?.[column]?.data[1]
        ) {
          return;
        } else {
          setVehiclePerformanceFilterSidebar({
            ...vehiclePerformanceFilterSidebar,
            filters: {
              ...vehiclePerformanceFilterSidebar?.filters,
              [column]: {
                ...vehiclePerformanceFilterSidebar?.filters?.[column],
                data: [
                  newValue,
                  vehiclePerformanceFilterSidebar?.filters?.[column]?.data[1],
                ],
              },
            },
          });
        }
      }
    }
    if (value === "max") {
      if (newValue === "") {
        setVehiclePerformanceFilterSidebar({
          ...vehiclePerformanceFilterSidebar,
          filters: {
            ...vehiclePerformanceFilterSidebar?.filters,
            [column]: {
              ...vehiclePerformanceFilterSidebar?.filters?.[column],
              data: [
                vehiclePerformanceFilterSidebar?.filters?.[column]?.data[0],
                "",
              ],
            },
          },
        });
      } else {
        setVehiclePerformanceFilterSidebar({
          ...vehiclePerformanceFilterSidebar,
          filters: {
            ...vehiclePerformanceFilterSidebar?.filters,
            [column]: {
              ...vehiclePerformanceFilterSidebar?.filters?.[column],
              data: [
                vehiclePerformanceFilterSidebar?.filters?.[column]?.data[0],
                newValue,
              ],
            },
          },
        });
      }
    }
  };

  const advancedFilters =
    vehiclePerformanceFilterSidebar?.loaded &&
    Object?.keys(vehiclePerformanceFilterSidebar?.filters)?.map((item) => {
      return (
        <div className="vp-sidebar-range-slider-group" key={item}>
          <p>{vehiclePerformanceFilterSidebar?.filters?.[item]?.label}</p>
          <RangeSlider
            values={{
              column: item,
              data: vehiclePerformanceFilterSidebar?.filters?.[item]?.data,
              minMax: vehiclePerformanceFilterSidebar?.filters?.[item]?.minMax,
            }}
            handleValueChange={(newValues) =>
              handleFilterValueChange(item, newValues)
            }
            handleFilterInputChange={(event, value) =>
              handleFilterInputChange(item, event, value)
            }
            stepValue={
              vehiclePerformanceFilterSidebar?.filters?.[item]?.stepValue
            }
          />
        </div>
      );
    });

  // Const: Emissions Filters
  const advancedFiltering = !filtersIsLoading && (
    <Accordion
      type="advancedFilters"
      titleName="Advanced Filters"
      children={<>{advancedFilters}</>}
      titleClassName="vp-sidebar-accordion-group"
    />
  );

  // TODO: REMOVE THE HARDCODED SLICE OF ADD EDIT SIDEBAR
  // Const: Add Columns Accordion
  const addColumnsAccordion = !filtersIsLoading && (
    <Accordion
      type="addColumns"
      titleName="Select Columns"
      children={
        <div className="vp-sidebar-nested-group">
          <SearchBar
            options={addEditSidebarVPColumnsOptions?.slice(
              1,
              filterColumnsSlice
            )}
            selectedOptions={vpDataSelectedColumns?.edit}
            onChange={(e) => handleDropdownChange(e, "columns")}
            isMultiSelect={true}
            placeholder="Search columns"
          />
        </div>
      }
      titleClassName="vp-sidebar-accordion-group"
    />
  );

  // Const: Clear Button Filter
  const clearFiltersButton = (
    <div className="clear-button-filter-container">
      <Button
        buttonName="Reset"
        onClick={() => {
          setClearButtonFilter({
            ...clearButtonFilter,
            [page]: !clearButtonFilter?.[page],
          });
          setFilterString("");
          setPartialSearchFilterString("");
          setVehicleProfileDataPage(1);
          // TODO!!: REMOVE HARDCODE SLICE - CHANGE THIS
          setVpDataSelectedColumns({
            edit: addEditSidebarVPColumnsOptions?.slice(1, 7),
            actual: addEditSidebarVPColumnsOptions?.slice(1, 7),
          });
        }}
      />
    </div>
  );

  // Const: Submit Button Filter
  const submitFiltersButton = (
    <div className="submit-button-filter-container">
      <Button
        buttonName="Submit"
        onClick={() => {
          setFilterString(
            generateSidebarFilterString(
              vehiclePerformanceFilterSidebar,
              filterVehicle
            )
          );
          setVpDataSelectedColumns({
            ...vpDataSelectedColumns,
            actual: vpDataSelectedColumns?.edit,
          });
          setVehicleProfileDataPage(1);
        }}
      />
    </div>
  );

  return (
    <div className="vp-sidebar-container">
      <div className="vp-filters-scroll-group">
        {filterHeader}
        {vehicleFilters}
        {page === "data" && addColumnsAccordion}
        {advancedFiltering}
      </div>
      <div className="vp-button-filter-container-group">
        {clearFiltersButton}
        {submitFiltersButton}
      </div>
    </div>
  );
}

// This file serves as a central location for all tile configuration variables related to to specific components that will be shown on hover icon.
import { FaRegChartBar } from "react-icons/fa";
import { BsQuestionSquare } from "react-icons/bs";

// Overview Page
export const emissionFootprintTile = {
  blurb: (
    <>
      <BsQuestionSquare id="emission-footprint-tile-blurb" />
      <div id="emission-footprint-blurb-group">
        Sum of scope 1 emissions (generated from your fleet) and scope 2
        emissions (generated from purchased electricity). Keep in mind you must
        enter your monthly utility information into scope 2 manager to populate
        scope 2 emissions.
      </div>
    </>
  ),
  legend: "",
};
export const industryCarbonRatingTile = {
  blurb: (
    <>
      <BsQuestionSquare id="industry-carbon-rating-tile-blurb" />
      <div id="industry-carbon-rating-blurb-group">
        0-100 rating that indicates how you perform against for-hire and private
        carrier members of the EPA Smartway Program. This compares your monthly
        emissions performance against the 3,092 members.
      </div>
    </>
  ),
  legend: (
    <>
      <FaRegChartBar id="industry-carbon-rating-tile-legend" />
      <div id="industry-carbon-rating-legend-group">
        <div className="industry-carbon-rating-legend-subgroup">
          <div className="icr-legend-subgroup-red"></div>
          <div>Significantly Poor Emissions Performance</div>
        </div>
        <div className="industry-carbon-rating-legend-subgroup">
          <div className="icr-legend-subgroup-orange"></div>
          <div>Poor Emissions Performance</div>
        </div>
        <div className="industry-carbon-rating-legend-subgroup">
          <div className="icr-legend-subgroup-yellow"></div>
          <div>Average Emissions Performance</div>
        </div>
        <div className="industry-carbon-rating-legend-subgroup">
          <div className="icr-legend-subgroup-ygreen"></div>
          <div>Above Average Emissions Performance</div>
        </div>
        <div className="industry-carbon-rating-legend-subgroup">
          <div className="icr-legend-subgroup-green"></div>
          <div>Significantly Above Average Emissions Performance</div>
        </div>
      </div>
    </>
  ),
};
export const costOfCarbonTile = {
  blurb: (
    <>
      <BsQuestionSquare id="cost-carbon-tile-blurb" />
      <div id="cost-carbon-blurb-group">
        This figure tells you the cost of every metric ton of CO₂ you emit. It
        is calculated by taking your monthly fleet emissions and dividing it by
        your monthly spend on fuel from fuel card purchases.
      </div>
    </>
  ),
  legend: "",
};
export const fuelConsumptionTile = {
  blurb: (
    <>
      <BsQuestionSquare id="fuel-consumption-tile-blurb" />
      <div id="fuel-consumption-blurb-group">
        This figure tells you the amount of fuel your fleet consumes per mile.
        It is calculated by taking your monthly fleet fuel spend and dividing it
        by your monthly fleet mileage.
      </div>
    </>
  ),
  legend: "",
};

export const vehicleEmissionsTile = {
  blurb: (
    <>
      <BsQuestionSquare id="vehicle-emissions-tile-blurb" />
      <div id="vehicle-emissions-blurb-group">
        Top eight highest emitting vehicles based upon the kilograms of CO₂ they
        emit per mile.
      </div>
    </>
  ),
  legend: (
    <>
      <FaRegChartBar id="vehicle-emissions-tile-legend" />
      <div id="vehicle-emissions-legend-group">
        <p>The industry benchmark is 1-3.5 kgCO₂e/mile.</p>
        <div className="vehicle-emissions-legend-subgroup ">
          <div className="vehicle-emissions-legend-subgroup">
            <div className="vehicle-emissions-legend-benchmark-green"></div>
            <div>Below Benchmark</div>
          </div>
          <div className="vehicle-emissions-legend-subgroup">
            <div className="vehicle-emissions-legend-benchmark-red"></div>
            <div>Above Benchmark</div>
          </div>
        </div>
      </div>
    </>
  ),
};

// Emissions Page
export const scope1ChartEmissionsTile = {
  blurb: (
    <>
      <BsQuestionSquare id="scope1-emissions-chart-tile-blurb" />
      <div id="scope1-emissions-chart-blurb-group">
        Scope 1 emissions are emissions generated by your trucking fleet. This
        chart displays your monthly fleet emissions over the past year, hover
        over a datapoint to view that month's emissions.
      </div>
    </>
  ),
  legend: (
    <>
      <FaRegChartBar id="scope1-emissions-chart-tile-legend" />
      <div id="scope1-emissions-chart-legend-group">
        The Industry Benchmark is calculated by multiplying the DOE's monthly
        average emissions for a HDV by the number of active vehicles in your
        fleet for the month. You can add it to the chart or take it away by
        clicking it.
      </div>
    </>
  ),
};
export const scope1CardEmissionsTile = {
  blurb: (
    <>
      <BsQuestionSquare id="scope1-emissions-card-tile-blurb" />
      <div id="scope1-emissions-card-blurb-group">
        The current month's scope 1 emissions. Below that is the increase or
        decrease in emissions from the previous month.
      </div>
    </>
  ),
  legend: <></>,
};
export const scope2ChartEmissionsTile = {
  blurb: (
    <>
      <BsQuestionSquare id="scope2-emissions-chart-tile-blurb" />
      <div id="scope2-emissions-chart-blurb-group">
        Scope 2 emissions are generated from purchased electricity, which is
        directly related to utilities or electric vehicle charging. You must
        enter in monthly utility bill information in scope 2 manager for data to
        be available.
      </div>
    </>
  ),
  legend: <></>,
};
export const scope2CardEmissionsTile = {
  blurb: (
    <>
      <BsQuestionSquare id="scope2-emissions-card-tile-blurb" />
      <div id="scope2-emissions-card-blurb-group">
        The current month's scope 2 emissions. Below that is the increase or
        decrease in emissions from the previous month.
      </div>
    </>
  ),
  legend: <></>,
};

export const lifecycleEmissionsTile = {
  blurb: (
    <>
      <BsQuestionSquare id="lifecycle-emissions-tile-blurb" />
      <div id="lifecycle-emissions-blurb-group">
        Lifecycle emissions include both the fossil fuel and biogenic portions
        across all three lifecycle stages—WTT, TTW, and WTW. Biogenic emissions
        are not reported separately from fossil fuel emissions due to the
        aggregated nature of GLEC emission factors, which are expressed in CO₂e
        rather than individual gas-specific factors. However, in Scope 1 and
        Scope 2 emissions, the biogenic portion is reported separately. For
        Scope 3 emissions GreenIRR reports both fossil and biogenic CO₂ portions
        of upstream emissions separately. However, due to the aggregated nature
        of GLEC's emission factors, complete disaggregation of biofuel CO₂
        emissions is not feasible. Therefore, GreenIRR reports the fossil
        portion of these emissions under Scope 3 and the biogenic portion
        independently in the Biogenic Emissions scope, using units of CO₂e. This
        method aligns with GLEC's best practice guidelines and ensures
        transparency in upstream biofuel emissions reporting
      </div>
    </>
  ),
  legend: <></>,
};

// Reduction
export const emissionReductionTargetTile = {
  blurb: (
    <>
      <BsQuestionSquare id="emission-reduction-target-tile-blurb" />
      <div id="emission-reduction-target-blurb-group">
        You can set your target by selecting your percent reduction from the
        drop down and your target year. The light bars represent the threshold
        you are “allowed” to emit to stay on track for the target, and the dark
        bars represent your actual monthly emissions. To stay on track you want
        your performance to be at or below the threshold.
      </div>
    </>
  ),
  legend: <></>,
};
export const forecastingChartTile = {
  blurb: (
    <>
      <BsQuestionSquare id="forecasting-chart-tile-blurb" />
      <div id="forecasting-chart-blurb-group">
        Future forecast of monthly emissions based upon monthly historical
        emissions.
      </div>
    </>
  ),
  legend: <></>,
};

// Vehicle Performance Data
export const emissionsPerVehicleTile = {
  blurb: (
    <>
      <BsQuestionSquare id="emissions-per-vehicle-tile-blurb" />
      <div id="emissions-per-vehicle-blurb-group">
        Emissions are broken down on a vehicle level based on active fuel
        consumption of each vehicle while driving. These are fossil fuel CO₂
        emissions only and do not include CH₄ or N₂O emissions. Vehicle-level
        biogenic emissions are reported separately under biogenic emissions in
        the report. The vehicle IDs match those of your telematic provider.
      </div>
    </>
  ),
  legend: (
    <>
      <FaRegChartBar id="emissions-per-vehicle-tile-legend" />
      <div id="emissions-per-vehicle-legend-group">
        Industry Benchmark is 10.16 in alignment with the DOE's monthly average
        for HDV
      </div>
    </>
  ),
};
export const avgEmissionsPerVehicleTile = {
  blurb: (
    <>
      <BsQuestionSquare id="avg-emissions-per-vehicle-tile-blurb" />
      <div id="avg-emissions-per-vehicle-blurb-group">
        Amount of CO₂ emissions per mile emitted on a vehicle-level taken as an
        average of all vehicles in the fleet. This is calculated on a monthly
        basis by taking the average emissions of each vehicle in your fleet in
        the current month month and dividing it by the average miles driven by
        each vehicle in that month. These are fossil fuel CO₂ emissions only and
        do not include CH₄ or N₂O emissions. Vehicle-level biogenic emissions
        are reported separately under biogenic emissions in the report and are
        not included in this calculation.
      </div>
    </>
  ),
  legend: (
    <>
      <FaRegChartBar id="avg-emissions-per-vehicle-tile-legend" />
      <div id="avg-emissions-per-vehicle-legend-group">
        Emissions should be within 1000-2500 gCO₂/mile
      </div>
    </>
  ),
};
export const totalIdleTimeTile = {
  blurb: (
    <>
      <BsQuestionSquare id="total-idle-time-tile-blurb" />
      <div id="total-idle-time-blurb-group">
        Total number of hours that vehicles idled in the current month.
      </div>
    </>
  ),
  legend: <></>,
};
export const totalIdleTimeEmissionsTile = {
  blurb: (
    <>
      <BsQuestionSquare id="total-idle-time-emissions-tile-blurb" />
      <div id="total-idle-time-emissions-blurb-group">
        Emissions generated from fuel consumed while idling. This fuel
        consumption is separate from active fuel consumption of the vehicle
        while driving. These are fossil fuel CO₂ emissions only and do not
        include CH₄ or N₂O emissions. Vehicle-level biogenic emissions are
        reported separately under biogenic emissions in the report and are not
        included in this calculation.
      </div>
    </>
  ),
  legend: <></>,
};
export const driveStatusVehicleProfile = {
  blurb: (
    <>
      <BsQuestionSquare id="drive-status-tile-blurb" />
      <div id="drive-status-blurb-group">
        Indicates whether a truck is active or not on a specific day.
      </div>
    </>
  ),
  legend: "",
};
export const driveIdleRatioVehicleProfile = {
  blurb: (
    <>
      <BsQuestionSquare id="drive-idle-ratio-tile-blurb" />
      <div id="drive-idle-ratio-blurb-group">
        The percentage of time a truck spends driving compared to idling.
      </div>
    </>
  ),
  legend: "",
};

export const daysActiveVehicleProfile = {
  blurb: (
    <>
      <BsQuestionSquare id="days-active-tile-blurb" />
      <div id="days-active-blurb-group">
        The number of days a truck is driven during a given month.
      </div>
    </>
  ),
  legend: "",
};

// Functionality: This file exports the VehicleEmissionsCard component which is used in the OverviewPage component. The component is a card including the highest emitting vehicles (monthly & yearly)4
import PropTypes from "prop-types";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import OverviewContext from "../../context/OverviewProvider";
import DateContext from "../../context/DateProvider";
import SwitchCarousel from "../../components/SwitchCarousel";
import Table from "../../components/Table";
import useSub from "../../hooks/use-Sub";
import Skeleton from "react-loading-skeleton";
import { highestEmittingVehiclesTableConfig } from "../../constants/VehicleConfig";
import { vehicleEmissionsTile } from "../../constants/TileConfig";

export default function VehicleEmissionsCard({ ...props }) {
  // Const: Initialize Context
  const { vehicleEmissions } = useContext(OverviewContext);

  // Const: Initialize Context - Dates
  const { applicationDates, setApplicationDates } = useContext(DateContext);

  // Const: Super Script Label for the Emissions
  const emissionLabel = useSub("Kg CO2e / mile");

  // Const: Label Application Dates
  const applicationDatesLabel = "vehicleEmissions";

  // Navigation Criteria
  const navigate = useNavigate();

  // Function: Handle Click on Vehicle Route to Profile
  const handleRouteVehicleProfile = (data) => {
    const index = data?.indexOf(" ");
    const part2 = data?.substring(index + 1);
    props.setActiveTab({
      ...props.activeTab,
      "/vehicle-performance": 2,
    });
    // Update the query parameter in the URL
    const newUrl = `/vehicle-performance?activeTab=2&vehicle-profile=${part2}`;
    navigate(newUrl);
  };
  return (
    <>
      {!vehicleEmissions?.loaded ? (
        <Skeleton height={"100%"} />
      ) : (
        <div className="hev-container">
          <div className="hev-title-container">
            <div className="hev-title-group">
              <div className="card-title">Highest Emitting Vehicles</div>
              {vehicleEmissionsTile?.blurb}
              {vehicleEmissionsTile?.legend}
            </div>
            <div className="group-switch-carousel-no-margin">
              <SwitchCarousel
                values={props.dateValues}
                applicationDates={applicationDates}
                setApplicationDates={setApplicationDates}
                applicationDatesLabel={applicationDatesLabel}
                fillArrowColor={"#66C743"}
                switchCarouselValueClassName="group-switch-carousel-value"
                changeVehicleProfile={true}
              />
            </div>
          </div>
          <div className="hev-table-container">
            <Table
              tableData={vehicleEmissions?.data.slice(
                0,
                props.windowSize?.width ? 4 : 9
              )}
              config={highestEmittingVehiclesTableConfig(
                emissionLabel,
                1,
                handleRouteVehicleProfile
              )}
              tableRowClassName="secondary-table-row card-text"
            />
            {props.windowSize?.width && (
              <Table
                tableData={vehicleEmissions?.data.slice(4)}
                config={highestEmittingVehiclesTableConfig(
                  emissionLabel,
                  2,
                  handleRouteVehicleProfile
                )}
                tableRowClassName="secondary-table-row card-text"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

// Props
const vehicleEmissionsPropTypes = {
  dateValues: PropTypes.array, // Array of dates to be shown in the date carousel
};

VehicleEmissionsCard.propTypes = vehicleEmissionsPropTypes;

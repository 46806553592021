// Functionality: This file exports the VPDataTable component which is used in the Vehicle Performance Page Data component.
import { useState, useContext, useRef, useEffect } from "react";
import DateContext from "../../../context/DateProvider";
import { AuthContext } from "../../../context/AuthProvider";
import VehiclePerformanceContext from "../../../context/VehiclePerformanceProvider";
import Table from "../../../components/Table";
import SwitchCarousel from "../../../components/SwitchCarousel";
import { vpDataTableConfig } from "../../../constants/VehicleConfig";
import Skeleton from "react-loading-skeleton";
import { TfiDownload } from "react-icons/tfi";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logoImage from "../../../assets/logo-green-black.png";
import { useLocation, useNavigate } from "react-router-dom";
import ToggleSwitch from "../../../components/ToggleSwitch";

// TODO!! FIX LAG IN PAGE
export default function VPDataTable({
  setActiveTab,
  activeTab,
  pageName,
  mockData,
  selectedColumns,
  setPartialSearchFilterString,
}) {
  // State
  const [downloadDropdown, setDownloadDropdown] = useState(false);
  const [downloadDataType, setDownloadDataType] = useState(false);
  const [togglePartialSearch, setTogglePartialSearch] = useState(false);
  const [partialSearchValues, setPartialSearchValues] = useState({}); // Partial Search Values

  // Ref
  const dropdownRef = useRef(null); // Ref to dropdown element

  // Navigation Criteria
  const location = useLocation();
  const navigate = useNavigate();

  // Function: Close the dropdown on click outside
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDownloadDropdown(false);
    }
  };

  // UE: Add event listener to close dropdown on click outside
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // Context: Get Values from Vehicle Performance Context
  const {
    vehiclePerformanceDataTable,
    vehiclePerformanceFilterEmissions,
    vehiclePerformanceFilterIdleTime,
    vehiclePerformanceFilterIdleTimeEmissions,
    vehiclePerformanceFilterVehicle,
    vehiclePerformanceFilterCH4,
    vehiclePerformanceFilterN2O,
    vehiclePerformanceFilterCarbonEmissions,
  } = useContext(VehiclePerformanceContext);

  // Context: Get Values from Date Context
  const { dateValues, applicationDates, setApplicationDates } =
    useContext(DateContext);

  // Context: Carrier Name
  const { carrierName } = useContext(AuthContext);

  // Function: Handle Download BOTH Click
  const handleDownloadDataClick = (tableData, type) => {
    // Close Dropdown & Clear Data Type
    setDownloadDropdown(false);
    setDownloadDataType(false);

    // Get Data in Proper Formatting
    const headers = [
      ["Vehicle ID"]?.concat(
        selectedColumns?.map((item) => {
          return item?.label;
        })
      ),
    ];
    const columns = [
      {
        title: "Vehicle",
        dataKey: "vehicle_id",
      },
    ].concat(
      selectedColumns?.map((item) => {
        return {
          title: item?.label,
          dataKey: item?.value,
        };
      })
    );
    // Map data explicitly to match selectedColumns order
    const mappedData = tableData?.map((item) => {
      const row = [item?.vehicle_id]; // Start with Vehicle ID
      selectedColumns?.forEach((column) => {
        row.push(item?.[column?.value] || ""); // Use column's dataKey to retrieve values
      });
      return row;
    });

    if (type === "pdf") {
      // Const: Set PDF Options & Dimensions
      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";

      // Const: Initialize PDF Document
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(8);

      // Parse the input date in yyyy-MM format
      const [year, month, day] = applicationDates?.vpData.split("-");
      const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1);

      const fullMonth = date.toLocaleString("en-US", { month: "long" });
      let formattedDate = `${fullMonth} ${year}`;

      let content = {
        startY: 60,
        margin: {
          top: 70,
          bottom: 60,
          left: 40,
          width: 522,
        },
        columns,
        body: mappedData?.map((row) => {
          return row?.map((item) => {
            return [item];
          });
        }),
        headStyles: {
          fillColor: "#00a63f",
        },
        showHead: "everyPage",
        didDrawPage: function (mappedData) {
          // Header
          doc.setFontSize(15);
          doc.setTextColor(40);
          const title = `Vehicle Performance Data  - ${formattedDate}`;
          doc.text(title, mappedData.settings.margin.left, 40);

          // Footer
          var str =
            "Page " + doc.internal.getNumberOfPages() + " - " + carrierName;

          doc.setFontSize(8);

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.text(str, mappedData.settings.margin.left, pageHeight - 40);
          doc.addImage(logoImage, "JPEG", 430, 20, 130, 30);
        },
      };

      // Add the image to the PDF
      doc.autoTable(content);
      doc.save(`vehicle_performance_${applicationDates?.vpData}`);
    } else if (type === "csv") {
      let csvContent = "data:text/csv;charset=utf-8,";

      csvContent += headers.join(",") + "\n";
      mappedData.forEach((rowArray) => {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        `vehicle_performance_${applicationDates?.vpData}.csv`
      );
      document.body.appendChild(link); // Required for FF
      link.click();
    }
  };

  // Function: Handle Dropdown Click
  const handleDownloadDropdownClick = () => {
    setDownloadDropdown(!downloadDropdown);
  };

  // Function: Handle Click on Vehicle Route to Profile
  const handleRouteVehicleProfile = (data) => {
    setActiveTab({
      ...activeTab,
      [pageName]: 2,
    });
    // Update the query parameter in the URL
    const searchParams = new URLSearchParams(location.search);

    // Set the profile-id query parameter
    searchParams.set("vehicle-profile", data);

    // Set the activeTab query parameter
    searchParams.set("activeTab", 2);

    // Construct the new URL
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    // Replace the current URL with the updated query parameter
    navigate(newUrl);
  };

  // Const: Download Icon Dropdown
  const downloadIconDropdown = (
    <>
      <TfiDownload
        strokeWidth={0.75}
        onClick={handleDownloadDropdownClick}
        className="vp-title-icon-group-svg"
      />
      {downloadDropdown && (
        <div className="vp-title-icon-dropdown">
          <p onClick={() => setDownloadDataType("pdf")}>PDF</p>
          <p onClick={() => setDownloadDataType("csv")}>CSV</p>
        </div>
      )}
    </>
  );

  // UE: Handle Partial Search Input Change
  useEffect(() => {
    if (Object?.keys(partialSearchValues)?.length > 0) {
      const timer = setTimeout(() => {
        const searchStr = Object.keys(partialSearchValues)
          .filter((key) => partialSearchValues[key])
          .map(
            (key) =>
              `&${key.replace(/_([a-z])/g, (_, letter) =>
                letter.toUpperCase()
              )}=${partialSearchValues[key]}`
          )
          .join("");
        setPartialSearchFilterString(searchStr);
      }, 3500);
      return () => clearTimeout(timer);
    } else {
      setPartialSearchFilterString(""); // Clear partialSearchString when no partial search values
    }
  }, [partialSearchValues]);

  const clearAllPartialSearch = () => {
    setPartialSearchValues({});
  };

  const clearSinglePartialSearch = (column) => {
    setPartialSearchValues((prevValues) => {
      const updatedValues = { ...prevValues };
      delete updatedValues[column]; // Remove the key-value pair
      return updatedValues; // Return the updated object
    });
  };
  return (
    <div className="vp-data-table-container">
      <img src={logoImage} alt="Logo" style={{ display: "none" }} />
      <div className="vp-data-title-group">
        <div className="vp-data-group-switch-carousel">
          <SwitchCarousel
            values={dateValues}
            applicationDates={applicationDates}
            setApplicationDates={setApplicationDates}
            applicationDatesLabel={"vpData"}
            fillArrowColor={"#66C743"}
            switchCarouselValueClassName="group-switch-carousel-value"
            changeVehicleProfile={true}
          />
        </div>
        <div></div>
        <div className="vp-data-title-group-right">
          <div className="flex-row-space-between">
            <p>Partial Search</p>
            <ToggleSwitch
              isToggled={togglePartialSearch}
              onToggle={() => {
                setTogglePartialSearch(!togglePartialSearch);
                setPartialSearchValues({});
              }}
              labelLeft=""
              labelRight=""
            />
          </div>
          {Object?.keys(partialSearchValues)?.length > 0 && (
            <p
              className="vp-data-title-group-right-clear"
              onClick={clearAllPartialSearch}
            >
              Clear All
            </p>
          )}
          <div className="vp-title-icon-group" ref={dropdownRef}>
            {downloadIconDropdown}
          </div>
        </div>
      </div>
      {vehiclePerformanceDataTable?.loaded ? (
        vehiclePerformanceDataTable?.data?.length > 0 ? (
          <div className="vp-data-table">
            <Table
              tableData={vehiclePerformanceDataTable?.data}
              config={vpDataTableConfig(
                handleRouteVehicleProfile,
                ["vehicle_id"]?.concat(
                  selectedColumns?.map((item) => {
                    return item.value;
                  })
                )
              )}
              tableRowClassName="primary-table-row card-text-min"
              sortable={true}
              tableHeadClassName="primary-table-sort-header-group card-text-min"
              minMaxFilters={
                mockData && [
                  vehiclePerformanceFilterEmissions,
                  vehiclePerformanceFilterIdleTime,
                  vehiclePerformanceFilterIdleTimeEmissions,
                  vehiclePerformanceFilterCH4,
                  vehiclePerformanceFilterN2O,
                  vehiclePerformanceFilterCarbonEmissions,
                ]
              }
              dropdownFilters={mockData && vehiclePerformanceFilterVehicle}
              download={downloadDataType}
              downloadFunction={handleDownloadDataClick}
              partialSearch={togglePartialSearch}
              partialSearchColumns={[
                "vin",
                "make",
                "model",
                "year",
                "fuel_type",
                "engine_type",
                "vehicle_type",
                "manufacturer_name",
              ]}
              handlePartialSearch={(searchValues, elss) => {
                setPartialSearchValues({
                  ...partialSearchValues,
                  [searchValues?.id]: elss,
                });
              }}
              partialSearchValues={partialSearchValues}
              clearPartialSearch={clearSinglePartialSearch}
            />
          </div>
        ) : (
          <p>
            No historical vehicle performance data available that meets the
            selected criteria.
          </p>
        )
      ) : (
        <Skeleton />
      )}
    </div>
  );
}
